











import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import Edit from '../../components/svg_saves/main/Edit.vue'
import ArrowUp from '../../components/svg_saves/main/ArrowUp.vue'
import ArrowDown from '../../components/svg_saves/main/ArrowDown.vue'
import Plus from '../../components/svg_saves/main/Plus.vue'
import TrashCan from '../../components/svg_saves/main/TrashCan.vue'
import MenuItem from './MenuItem.vue'

@Component({
  components: {
    MenuItem,
    Edit,
    ArrowUp,
    ArrowDown,
    Plus,
    TrashCan
  },
  data() {
    return {
      Edit,
      ArrowUp,
      ArrowDown,
      Plus,
      TrashCan
    }
  }
})
export default class Menu extends Vue {
  @Prop({ required: true, default: '' }) public visible!: boolean // 是否显示Menu
  @Prop({ required: true, default: '' }) public offsetX!: string // Menu left 值
  @Prop({ required: true, default: '' }) public offsetY!: string // Menu top 值
  @Prop({ required: true, default: true }) public isMoveUpshow!: boolean // 是否显示Menu
  @Prop({ required: true, default: true }) public isMoveDownshow!: boolean // 是否显示Menu

  @Watch('offsetX')
  public updateOffsetX() {
    this.$nextTick(() => {
      (this.$refs.menu as any ).style.left = this.offsetX
    })
  }

  @Watch('offsetY')
  public updateOffsetY() {
    this.$nextTick(() => {
      (this.$refs.menu as any).style.top = this.offsetY
    })
  }

  /**
   * Update 生命周期
   */
  public updated() {
      this.$nextTick(() => {
        (this.$refs.menu as any).style.left = this.offsetX;
        (this.$refs.menu as any).style.top = this.offsetY
    })
  }

  /**
   * Mounted 生命周期
   */
  public mounted() {
    // 将该组件直接挂载至body中
    document.body.insertBefore(this.$el, document.body.lastChild)

    this.$nextTick(() => {
      (this.$refs.menu as any).style.left = this.offsetX;
      (this.$refs.menu as any).style.top = this.offsetY
    })

    document.addEventListener('click', (e: any) => {
      // 点击该组件以外区域时
      const re = /^menu-item/

      if (!re.test(e.target.className)) {
        this.$emit('onClose', e)
      }
    })
  }
  /**
   * 编辑
   */
  public handleEdit(e) {
    this.$emit('onEdit', e)
  }

  /**
   * 上移
   */
  public handleMoveUp(e) {
    this.$emit('onMoveUp', e)
  }

  /**
   * 下移
   */
  public handleMoveDown(e) {
    this.$emit('onMoveDown', e)
  }

  /**
   * 新建组织
   */
  public handleCreate(e) {
    this.$emit('onCreate', e)
  }

  /**
   * 删除
   */
  public handleDelete(e) {
    this.$emit('onDelete', e)
  }
}
