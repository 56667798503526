import { requestToken } from '../../../api/manage'
import { getStorageUserType } from '@/utils/util'
import { OrgInfo } from '@/interfaces'

// -----------------------测试数据
function getUser() {
    // ------------UserData-----------
    const data = [{
        key: '1',
        id: 'xx1',
        UserName: 'John Brown',
        PersonType: 'A',
        PersonNumber: '72',
        Gender: 'M',
        Age: '23',
        Phone: '1111111111',
        Email: 'aa@xx.com',
        link: 'UserDetail',
    } ]
    for (let i = 3; i < 100; i++) {
        data.push({
            key: `${i}${i}`,
            id: `1${i}`,
            UserName: `UserName ${i}`,
            PersonType: `A${i}`,
            PersonNumber: `${i}${i}`,
            Gender: 'F',
            Age: `${i}`,
            Phone: `1${i}1${i}11${i}${i}1`,
            Email: `${i}${i}aa@xx.com`,
            link: 'UserDetail',
        })
    }
    return data
}
export const UDData = getUser()

function getDData() {
    // ------------DeviceData-----------
    const DeviceData = [{
        key: '1',
        DKname: 'John Brown',
        DeviceType: 'A',
        DName: 'Device Name',
        lanIp: '72',
        chipId: 'M11',
        link: 'DeviceDetail',
        id: 'DeviceData1',
        deviceInfo: 'deviceInfoxx1',
    } ]
    for (let j = 2; j < 100; j++) {
        DeviceData.push({
            key: j.toString(),
            DKname: `DeviceDetail ${j}`,
            DeviceType: 'B',
            DName: `Device Name${j}`,
            lanIp: `${j}`,
            chipId: `ABC${j}`,
            link: 'DeviceDetail',
            id: `DeviceData${j}`,
            deviceInfo: `deviceInfoxx${j}`,
        })

    }
    return DeviceData
}
export const DData = getDData()
// -----------------------测试数据


// update=======================
export function updateOrg(orgId: number, data: any)  {
    return requestToken({
        url: `/api/v1/organizations/${getStorageUserType()}/updateOrg/${orgId}`,
        method: 'put',
        data
    })
}
// 更新企业信息（管理员）
export function updateORGBySuper(organId, name) {
    return requestToken({
        url: '/api/v1/organizations/superuser',
        method: 'put',
        data: { organ_id: organId, name },
    })
}
// 更新企业信息（企业）
export function updateORGByORG(organId, name) {
    return requestToken({
        url: '/api/v1/organizations/manager',
        method: 'put',
        data: { organId, name },
    })
}
// 更新企业信息（操作员）
export function updateORGByOPT(organId, name) {
    return requestToken({
        url: '/api/v1/organizations/operator',
        method: 'put',
        data: { organId, name },
    })
}
// creat===================
// 创建企业信息（管理员）
export function creatORGBySpuer(data) {
    return requestToken({
        url: '/api/v1/organizations/superuser',
        method: 'post',
        data,
    })
}

// 创建企业信息（企业）
export function creatORGByORG(data) {
    return requestToken({
        url: '/api/v1/organizations/manager',
        method: 'post',
        data,
    })
}

// 创建企业信息（操作员）
export function creatORGByOPT(data) {
    return requestToken({
        url: '/api/v1/organizations/operator',
        method: 'post',
        data,
    })
}
