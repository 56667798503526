



















































import { Vue, Component , Prop, Watch} from 'vue-property-decorator'
import { addNewGroup, getRanterTreeData , updateInfo} from '../../../interfaces/service/org/enterpriseMang'
import _ from 'lodash'
import { commitSetLoading, commitSetOrgChangeData } from '../../../store/main/mutations'
import { createHash } from '../../../utils/util'
const returnData: any = { visable: true }
@Component({
  data() {
    return { replaceFields: {
               key: 'id',
               children: 'children',
               title: 'name',
               value: 'id',
               scopedSlots: 'scopedSlots',
              },
      }
  },
})
export default class NewAddModel extends Vue {
  @Prop() public modelFlag!: string // 判断是update 还是 insert
  @Prop() public getORGEditInfo!: any // 取得组织传递过来的信息包含 组织名称，父ID，组织自身ID
  public theRanterIDs: string = sessionStorage.getItem('ranterId') || ''
  public dataList: any = []
  public theEname: string = ''
  public theECode: string = ''
  public orgData = this.$store.state.main.enterprise.enterpriseData ? _.compact(
    this.$store.state.main.enterprise.enterpriseData.map((e) => {
      if (this.theRanterIDs === e.id.toString()) {
        return e
      }
    })
  ) : []
  public subLoading: boolean = false
  public orgFilter: any = []
  public sameNameCount: number = 0
  public sameCodeCount: number = 0
  public parentChose: string = ''
  public orgIDChose: number | null = 0
  public orgIdBak: number | null = 0 // 更新时判断根组织是否变更用
  public showOrgCode: boolean = this.$store.state.main.enterprise.showOrgCode
  @Watch('$store.state.main.enterprise.showOrgCode') public watchShowOrgCode() {
    this.showOrgCode = this.$store.state.main.enterprise.showOrgCode
  }
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }
  public modelCancel() {
    returnData.visable = false
    this.$emit('modelFun', returnData)
  }
  public getMaxSort() {
    let maxSort: number = 0
    const arr =
    _.find(this.$store.state.main.enterprise.enterpriseHashData || {}, (e: any) => {
      return e.parent_id === this.orgIDChose
    })
    maxSort = _.compact([arr]).length > 0 ? arr.max_sort + 1 : maxSort
    return maxSort
  }
  public async modelSubmit() {
    try {
      commitSetLoading(this.$store, true)
      if (!this.theEname || !this.orgIDChose || (this.showOrgCode && !this.theECode)) {
        this.$message.error(this.$i18n.tc('enterprise.new_org.error_required'))
        commitSetLoading(this.$store, false)
        return
      }
      if (this.modelFlag === 'update') {
        returnData.visable = false
        // 根节点变更时sort顺重新计算
        let maxsort = this.getORGEditInfo.sort
        if (this.orgIDChose !== this.orgIdBak) {
          maxsort = this.getMaxSort()
        }
        const param: any = {
              name: this.theEname,
              parent_id: this.orgIDChose,
              id: this.getORGEditInfo.id,
              sort: maxsort,
        }
        if (this.showOrgCode) {
          param.code = this.theECode
        }
        await updateInfo(param, this.$store.state.main.userProfile.user_type)
        await getRanterTreeData(this.$store.state.main.userProfile.user_type)
        this.$emit('modelFun', returnData)
      } else {
        this.handelEname(this.theEname)
        if (this.showOrgCode) {
          this.handelECode(this.theECode)
        }
        if (this.sameNameCount > 0) {
          this.$message.error(this.$i18n.tc('enterprise.new_org.error_duplicated_name'))
          commitSetLoading(this.$store, false)
          return
        }
        if (this.showOrgCode && this.sameCodeCount > 0) {
          this.$message.error(this.$i18n.tc('enterprise.new_org.error_duplicated_code'))
          commitSetLoading(this.$store, false)
          return
        }
        // 不存在重名
        returnData.visable = false
        const maxSort : any = this.getMaxSort()
        const param: any = {
          name: this.theEname,
          parent_id: this.orgIDChose,
          sort: maxSort,
        }
        if (this.showOrgCode) {
          param.code = this.theECode
        }
        await addNewGroup(this.$store.state.main.userProfile.user_type, param)
        await getRanterTreeData(this.$store.state.main.userProfile.user_type)
        this.$emit('modelFun', returnData)
        commitSetOrgChangeData(this.$store, Number(new Date()))
      }
      const enterpriseData = await getRanterTreeData(this.$store.state.main.userProfile.user_type)
      // 重新获取 hash
      const enterpriseHashData = createHash(enterpriseData, {})
      this.$store.commit('setEnterpriseHashData', enterpriseHashData)
      this.orgData = _.compact(
        this.$store.state.main.enterprise.enterpriseData.map((e) => {
          if (this.theRanterIDs === e.id.toString()) {
            return e
          }
        })
      )
      commitSetLoading(this.$store, false)
    } catch (e) {
      console.log(e)
      return
    }
  }
  public generateList(data) {
    for (const i of data) {
      const node: any = i
      const id: number = node.id
      this.dataList.push({ id, name: node.name , code: node.code, parent_id: node.parent_id})
      if (node.children) {
        this.generateList(node.children)
      }
    }
  }
  public orgSelect(value) {
    this.dataList.map((e) => {
      if (e.name.indexOf(value) > -1) {
        this.parentChose = e.name
        this.orgIDChose = e.id
      }
    })
  }
  public orgIDSearchInit() {
    this.generateList(this.orgData)
  }
  public handelEname(e) {
    // 处理重名(同一组织下不可重名,租户范围可以重名)
    let sameNameCount = 0
    this.dataList.map((obj) => {
      if (obj.name.toString() === e.toString()) {
        if (this.orgIDChose === obj.parent_id) {
          sameNameCount++
          return
        }
      }
    })
    this.sameNameCount = sameNameCount
  }
  public handelECode(e) {
    // 处理重名(同一组织下不可重名,租户范围可以重名)
    let sameCodeCount = 0
    this.dataList.map((obj) => {
      if (obj.code && obj.code.toString() === e.toString()) {
        if (this.orgIDChose === obj.parent_id) {
          sameCodeCount++
          return
        }
      }
    })
    this.sameCodeCount = sameCodeCount
  }
  private created() {
    this.orgIDSearchInit()
    if (this.modelFlag === 'update') {
      this.theEname = this.getORGEditInfo.name
      const orgCode = this.getORGEditInfo.code
      this.theECode = orgCode // (orgCode && orgCode.length > 1) ? orgCode.substring(orgCode.length - 2) : orgCode
      this.dataList.map((e) => {
        if (e.id === this.getORGEditInfo.parent_id) {
          this.orgIDChose = e.id
          this.orgIdBak = e.id
          return
        }
      })
    } else {
      this.theEname = ''
      this.theECode = ''
      if (this.getORGEditInfo.parent_id) {
        this.orgIDChose = this.getORGEditInfo.parent_id
      } else {
        this.orgIDChose = Number.parseInt(this.theRanterIDs, 10)
      }
    }
  }
}
