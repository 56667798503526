










import { Vue, Prop, Component } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class MenuItem extends Vue {
  @Prop({ required: false, default: '' }) public icon!: Vue.Component // 菜单栏icon
  @Prop({ required: false, default: '' }) public content!: string // 菜单栏文本
  @Prop({ required: false, default: false }) public danger!: boolean // 是否显示危险颜色

  public get isIconSlot() : boolean {
    return _.has(this.$slots, 'api')
  }

  /**
   * 派发点击事件至Menu组件
   */
  public handleClick(e) {
    this.$emit('click', e)
  }
}
